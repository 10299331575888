import config from "config"
import EventEmitter from "events"
import dayjs from "dayjs"
import { $get } from "storemoodify/util/get"
import Queue from "storemoodify/util/Queue"
import FormData from "../util/form-data"
import { POST_FORM } from "../util/resource"

const EXIF_DIFFERENCE_DAYS = 7
const ENDPOINT = config.upload
// eslint-disable-next-line no-unused-vars
const SMALL_RESOLUTIONS = { width: 4900, height: 3200 }

const ALLOWED_TYPES = ["image/jpg", "image/jpeg"]

const CONCURRENCY = 5
const MAX_CONVERTED_PROGRESS = 20

const CONVERTER_CONFIG = {
    // watermark: "/images/watermark.png",
    path: {
        zxing: "https://unpkg.com/@zxing/library@0.18.6/umd/index.min.js",
        exifjs: "https://cdn.jsdelivr.net/npm/exifr@4.2.0/dist/full.umd.js",
    },
    resize: {
        preview: {
            size: "800x800",
            quality: 0.9,
            watermark: false,
            alignOrientation: true,
        },
        previewWatermarked: {
            size: "800x800",
            quality: 0.9,
            watermark: true,
            alignOrientation: true,
        },
        previewHQ: {
            size: "1200x1200",
            quality: 0.9,
            watermark: false,
            alignOrientation: true,
        },
        thumbnail: {
            size: "300x300",
            quality: 0.8,
            watermark: false,
            strict: true,
            alignOrientation: true,
        },
        embedThumbnail: {
            size: "60x60",
            quality: 0.2,
            type: "image/webp",
            output: "base64",
            watermark: false,
            strict: true,
            alignOrientation: true,
        },
    },
}

const UError = (message, file) => Object.assign(new Error(message), { file, details: message })
const UWarning = (message, file) => Object.assign(new Error(message), { file, details: message })

const getTimezoneOffset = () => new Date().getTimezoneOffset() * 60 * -1

const getExifTime = (exif) => {
    console.log('exif', exif)
    console.log('exif.MediaCreateDate', exif.MediaCreateDate)
    console.log('exif.TrackCreateDate', exif.TrackCreateDate)
    console.log('exif.CreateDate', exif.CreateDate)
    console.log('exif.DateTimeOriginal', exif.DateTimeOriginal)
    console.log('exif.Metadata Date', exif['Metadata Date'])
    console.log('exif.MetadataDate', exif.MetadataDate)
    console.log('exif.DateTime', exif.DateTime)
    return Math.floor(new Date(exif.DateTimeOriginal).getTime() / 1000) + getTimezoneOffset()
}

const convertCoordinates = (data, direction) => {
    if (!data) {
        return null
    }
    const [degrees, minutes, seconds] = data
    let dd = degrees + (minutes / 60) + (seconds / 3600)
    if (direction === "S" || direction === "W") {
        dd = dd * -1
    }
    return dd
}

const getPhotoMetadata = (file, { width, height, exif, previews }) => {
    return {
        EXIF: {
            OriginalFileName: file.name,
            OriginalSize: file.size,
            ISO: exif.ISO,
            Rotation: "Orientation" in exif ? exif.Orientation : 1,
            Time: getExifTime(exif),
            SubSecTime: $get(exif, "SubSecDateTimeOriginal", ""),
            Width: width,
            Height: height,
            GPSLatitude: $get(exif, "GPSLatitude", null),
            GPSLongitude: $get(exif, "GPSLongitude", null),
            GPSLatitudeRef: $get(exif, "GPSLatitudeRef", null),
            GPSLongitudeRef: $get(exif, "GPSLongitudeRef", null),
            Latitude: convertCoordinates($get(exif, "GPSLatitude", null), $get(exif, "GPSLatitudeRef", null)),
            Longitude: convertCoordinates($get(exif, "GPSLongitude", null), $get(exif, "GPSLongitudeRef", null)),
            LensModel: $get(exif, "LensModel", null),
            LensSerialNumber: $get(exif, "LensSerialNumber", "") || "",
            BodySerialNumber: $get(exif, "BodySerialNumber", "") || "",
            CameraSerialNumber: $get(exif, "CameraSerialNumber", "") || "",
            SerialNumber: $get(exif, "SerialNumber", "") || "",
            InternalSerialNumber: $get(exif, "InternalSerialNumber", "") || "",
            ShutterCount: $get(exif, "ShutterCount", "") || $get(exif, "FileIndex", "") || $get(exif, "ImageNumber", "") || $get(exif, "ImageUniqueID", "") || "",
            Make: $get(exif, "Make", null),
            Model: $get(exif, "Model", null),
            Aperture: $get(exif, "ApertureValue", null),
            Brightness: $get(exif, "BrightnessValue", null),
            ExposureTime: $get(exif, "ExposureTime", null),
            WhiteBalance: String($get(exif, "WhiteBalance", 0)),
        },
        Resolutions: {
            Original: { Width: width, Height: height },
            Preview: { Width: previews.preview.width, Height: previews.preview.height },
            PreviewWaterMark: { Width: previews.previewWatermarked.width, Height: previews.previewWatermarked.height },
            Thumbnail: { Width: previews.thumbnail.width, Height: previews.thumbnail.height },
            EmbedThumbnail: { Width: previews.embedThumbnail.width, Height: previews.embedThumbnail.height },
        },
    }
}

const getPayload = (data) => {
    return {
        OriginalFileName: data.input.name,
        OriginalSize: data.input.size,
        Preview: data.previews.preview.data,
        PreviewHQ: data.previews.previewHQ.data,
        Original: data.input,
        PreviewWatermarked: data.previews.previewWatermarked.data,
        Thumbnail: data.previews.thumbnail.data,
        EmbedThumbnail: data.previews.embedThumbnail.data,
        metadata: JSON.stringify(getPhotoMetadata(data.input, data)),
        TimeZone: getTimezoneOffset(),
    }
}

const isSmallResolutions = ({ width, height }) => {
    const photoResolutions = [width, height].sort((a, b) => b - a)
    const availableResolutions = [SMALL_RESOLUTIONS.width, SMALL_RESOLUTIONS.height].sort((a, b) => b - a)

    return availableResolutions[0] > photoResolutions[0] ||
        availableResolutions[1] > availableResolutions[1]
}

export default class Uploader extends EventEmitter {
    constructor(store, params) {
        super()
        this.uploadQueue = new Queue(CONCURRENCY)
        this.store = store
        this.params = params
        this.files = 0
        this.convertedFiles = 0
        this.convertedCodes = 0
        this.convertErrors = 0
        this.uploadErrors = 0
        this.uploaded = 0
    }

    add(files) {
        this.files += files.length

        const appendFiles = files.filter(file => {
            if (!ALLOWED_TYPES.includes(file.type)) {
                this.convertErrors++
                this.onError(UError("Invalid file type", file))
                return false
            }
            return true
        })

        this.getConverter().then(c => {
            c.append(appendFiles)
            this.tick()
        })
    }

    onConvertFile(data) {
        if (data.type === "image") {
            this.onConvertImage(data)
        } else {
            this.onCovertQrCode(data)
        }
    }

    onConvertImage(data) {
        this.uploadQueue.push(() => this.uploadFile(data))
        this.convertedFiles++
        this.checkResolutions(data)
        this.checkExifDate(data)
        this.tick()
    }

    onCovertQrCode(data) {
        this.store.dispatch("promo/extractCardCode", data.qr).then(code => {
            if (code === null) {
                throw new Error("Invalid code")
            }

            this.emit("detect:qr", { code, time: dayjs(data.date).unix() })
            this.convertedCodes++
            this.tick()
        }).catch(() => {
            this.getConverter().then(c => c.append([data.input], true))
        })
    }

    onConvertFileError({ file, error }) {
        if (error.type === "error") {
            this.onError(UError("Invalid file type", file))
        } else {
            this.onError(UError(error.message, file))
        }
        this.convertErrors++
        this.tick()
    }

    tick() {
        const converted = this.convertedFiles + this.convertErrors + this.convertedCodes
        const uploaded = this.uploaded + this.uploadErrors
        const done = converted === this.files && uploaded === this.convertedFiles

        if (done) {
            return this.emit("done")
        }

        const convertRatio = MAX_CONVERTED_PROGRESS / 100
        const uploadRatio = (100 - MAX_CONVERTED_PROGRESS) / 100
        const convertProgress = (converted / this.files) * 100
        const uploadProgress = (uploaded / this.files) * 100

        const percents = (convertProgress * convertRatio) + (uploadProgress * uploadRatio)

        this.emit("progress", {
            percents: Math.ceil(percents),
            uploaded: this.uploaded,
            uploadErrors: this.uploadErrors,
            converted: this.convertedFiles,
            convertedCodes: this.convertedCodes,
            convertErrors: this.convertErrors,
        })
    }

    deferUpload(data) {
        return new Promise(resolve => setTimeout(resolve, 2000)) // wait for connection established
            .then(() => this.uploadFile(data))
    }

    uploadFile(data) {
        console.log('uploadFile-------------', data)
        const payload = Object.assign(getPayload(data), this.params)|> FormData.fromJSON
        return POST_FORM(ENDPOINT, payload)
            .then((response) => {
                if (response.status !== 200) {
                    return Promise.reject(response)
                }
                return response
            })
            .then((response) => this.onUploadSuccess(response, data))
            .catch((response) => this.onUploadError(response, data))
    }

    onNetworkError(error, data) {
        this.uploadQueue.push(() => this.deferUpload(data))
        this.emit("retry", { error, data })
    }

    checkResolutions({ width, height, input }) {
        if (isSmallResolutions({ width, height })) {
            this.emit("warning", UWarning("small photo resolutions", input))
        }
    }

    checkExifDate(data) {
        console.log('checkExifDate-----------', data)
        const date = dayjs(data.date)
        const now = dayjs()

        if (date.isBefore(now.subtract(EXIF_DIFFERENCE_DAYS, "day"))) {
            this.emit("warning", UWarning("early exif date", data.input))
        } else if (now.isBefore(date)) {
            this.emit("warning", UWarning("exif date more than now", data.input))
        }
    }

    async onUploadSuccess(response) {
        const json = await response.json()
        this.emit("upload", json)
        this.uploaded++
        this.tick()
    }

    async onUploadError(response, data) {
        if (response.status === 0 || response instanceof Error) {
            this.onNetworkError(response, data)
            return
        }

        const text = await response.text()
        this.onError(UError(text, data.input))
        this.uploadErrors++
        this.tick()
    }

    onError(e) {
        this.emit("error", e)
    }

    getConverter() {
        if (this.converter) {
            return Promise.resolve(this.converter)
        }

        return import("image-converter").then(Converter => {
            const constructor = Converter.default
            const converter = new constructor(CONVERTER_CONFIG)
            converter
                .on("data", data => this.onConvertFile(data))
                .on("error", err => this.onConvertFileError(err))
            return (this.converter = converter)
        })
    }
}

