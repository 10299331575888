<template>
    <div>
        <v-list dense>
            <v-list-item v-for="(error, index) in getErrors" :key="index">
                <v-icon :color="iconColor" class="mr-2">{{icon}}</v-icon>
                <span class="blue--text d-inline">{{error.filename}}</span>:<span v-html="`&nbsp;`"></span><span
                    v-html="error.text"></span>
            </v-list-item>
        </v-list>
    </div>
</template>
<script>
import { ResourceError } from "storemood-connection"
import $get from "storemoodify/util/get"

export default {
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        icon: {
            type: String,
            default: "mdi-alert-circle",
        },
        iconColor: {
            type: String,
            default: "red",
        },
    },
    computed: {
        getErrors() {
            const errors = this.list
            return errors.map(e => ({ text: this.getErrorText(e), filename: this.getFilename(e) }))
        },
    },
    methods: {
        getErrorText(error) {
            let message = ""

            new ResourceError(error)
                .ifCode("photo already exist", () => (message = this.$t(`errors['photo exists']`)))
                .ifCode("empty exif", () => (message = this.$t(`errors['no exif']`) + `<b>${this.$t("partial.settings")}</b>`))
                .ifCode("invalid file type", () => (message = this.$t(`errors['no exif']`) + `<b>${this.$t("partial.jpg")}</b>`))
                .ifCode("decode error", () => (message = this.$t(`errors['decode error']`)))
                .ifCode("small photo resolutions", () => (message = this.$t(`errors['small photo resolutions']`)))
                .ifCode("early exif date", () => (message = this.$t(`errors['week ago']`)))
                .ifCode("early exif date", () => (message = this.$t(`errors['early exif date']`)))
                .else(() => (message = this.$t(`errors['upload error']`)))

            return message
        },

        getFilename(error) {
            return $get(error, "file.name", "")
        },
    },
}
</script>
<style lang="stylus">

</style>
