<template>
    <div>

        <div v-if="foreverLoading" class="d-flex mt-4 mb-4 justify-center">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-3"
                indeterminate
            ></v-progress-circular>
        </div>

        <div v-show="!foreverLoading">

            <v-row align="center" dense class="pl-2">
                <div>
                    <header class="headline">{{ $t(`headers['shoot place']`) }}</header>
                </div>
                <v-col cols="6" class="pl-4" dense>
                    <v-autocomplete
                        autocomplete="off"
                        v-model="place"
                        :items="getPlaces"
                        color="blue-grey lighten-2"
                        :label="$t(`labels['choose shoot place']`)"
                        item-text="name"
                        item-value="id">

                        <template v-slot:item="data">
                            <v-list-item-avatar>
                                <v-img :src="data.item.image"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col auto dense></v-col>
            </v-row>

            <div v-if="canVisiblePhotographers" class="mt-8">
                <header class="headline">{{ $t(`headers['photographer']`) }}</header>
                <div class="my-4" v-show="loadingPhotographers">
                    <v-progress-circular indeterminate size="24" color="indigo darken-2"></v-progress-circular>
                    {{ $t(`messages['loading photographers']`) }}
                </div>

                <div v-show="!loadingPhotographers && getPhotographers.length > 0">
                    <v-radio-group v-model="photographer" row>
                        <v-radio v-for="photographer in getPhotographers"
                                 :key="photographer.id"
                                 :label="photographer.name"
                                 :value="photographer.id"/>
                    </v-radio-group>
                </div>

                <div class="mt-4" v-show="!loadingPhotographers && !getPhotographers.length">
                    {{ $t(`messages['phorographers not found']`) }}
                </div>
            </div>
        </div>

        <v-btn outlined ripple color="blue-grey darken-1" v-show="canVisibleSubmit" @click="onSubmit()">
            {{ $t("actions.submit") }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import * as Format from "../util/format"

export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            loadingPlaces: false,
            loadingPhotographers: false,
            places: [],
            photographers: [],
            place: this.value.place || null,
            photographer: this.value.photographer || null,
            foreverLoading: false,
        }
    },

    computed: {
        ...mapGetters("common", ["getUserId", "isJesus"]),
        getPlaces() {
            return this.places.map(place => ({
                id: place.ID,
                name: Format.getName(place.Name),
                image: Format.getMedia(place.Avatar.Cropped, "place"),
            }))
        },

        getPhotographers() {
            return this.photographers.map(photographer => ({
                id: photographer.ID,
                name: Format.getUserName(photographer.Name),
            }))
        },

        getCurrentPlace() {
            return this.places.find(p => p.ID === this.place)
        },

        isPlaceAdmin() {
            const admins = (this.getCurrentPlace || {}).Admins || []
            return admins.includes(this.getUserId) || this.isJesus
        },

        getCurrentPhotographer() {
            return this.photographers.find(p => p.ID === this.photographer)
        },

        canVisiblePhotographers() {
            return !!this.getCurrentPlace
        },

        canVisibleSubmit() {
            return !!this.getCurrentPhotographer
        },
    },

    watch: {
        place(val) {
            if (val) {
                this.photographer = null
                this.$nextTick().then(() => this.onSelectPlace())
            }
        },
        getCurrentPhotographer(val) {
            if (val) {
                this.onSubmit()
            }
        },
    },

    created() {
        this.fetchPlaces()
    },

    methods: {
        ...mapActions("user", ["getUploadPlaces", "getByIds"]),

        onSubmit() {
            if (this.getCurrentPhotographer && this.getCurrentPlace) {
                this.$emit("submit", { place: this.getCurrentPlace, photographer: this.getCurrentPhotographer })
            }
        },

        fetchPlaces() {
            this.foreverLoading = true
            this.loadingPlaces = true
            this.getUploadPlaces()
                .then(places => this.onLoadPlaces(places))
                .finally(() => this.loadingPlaces = false)
        },

        onSelectPlace() {
            const promise = this.fetchPhotographers()
            if (!this.isPlaceAdmin) {
                promise.then(() => {
                    this.photographer = this.getUserId
                    this.$nextTick().then(() => {
                        this.onSubmit()
                        this.foreverLoading = false
                    })
                })
            } else {
                this.foreverLoading = false
            }
        },

        fetchPhotographers() {
            const { Photographers } = this.getCurrentPlace
            this.loadingPhotographers = true
            this.photographers = []

            return this.getByIds(Photographers)
                .then((result) => {
                    if (this.isPlaceAdmin) {
                        this.photographers = result
                    } else {
                        this.photographers = result.filter(user => user.ID === this.getUserId)
                    }
                })
                .finally(() => this.loadingPhotographers = false)
        },

        onLoadPlaces(places) {
            const routePlace = this.$route.query.place
            this.places = places
            this.place = routePlace

            if (this.places.length === 1) {
                this.place = this.places[0].ID
            }
            this.foreverLoading = !!this.place
        },
    },
}
</script>
