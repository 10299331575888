<template>
    <v-container fluid style="background: #fff">
        <div class="d-flex align-center mb-8">
            <h2 class="my-2 ">{{ $t(`toolbar['upload']`) }}</h2>
            <div class="flex text-right">
                <v-btn v-if="uploadState.loadedPhotogroups" outlined ripple color="blue-grey darken-1"
                       @click="onClickSave()">
                    {{ $t("actions.save") }}
                </v-btn>
            </div>
        </div>

        <upload-target-control v-show="!canUploadFiles" @submit="onSubmitTarget"/>

        <div v-if="canUploadFiles" class="subtitle-1">
            {{ $t(`rudiments['will upload to']`, [getTargetPlaceName, getTargetPhotographerName]) }}
            <v-btn
                v-show="!uploadState.selected"
                @click="onClickClear()"
                text small color="primary" class="ml-4">{{ $t("actions.change") }}
            </v-btn>
        </div>

        <v-row v-show="canUploadFiles">
            <v-col>
                <div class="page-upload__labels" v-show="uploadState.selected">
                    <v-chip
                        :close="uploadState.visibleErrors"
                        @click="onClickErrorsLabel"
                        @click:close="onClickErrorsLabel"
                        class="ma-2"
                        color="red"
                        text-color="red"
                        close-icon="mdi-close"
                        label
                        outlined>
                        <v-icon left>mdi-close-circle</v-icon>
                        {{ $t(`messages['errors count']`, [uploadState.errors.length]) }}
                    </v-chip>

                    <v-chip
                        :close="uploadState.visibleWarnings"
                        @click="onClickWarningsLabel"
                        @click:close="onClickErrorsLabel"
                        class="ma-2"
                        color="orange"
                        close-icon="mdi-close"
                        label
                        outlined
                        text-color="orange">
                        <v-icon left>mdi-alert-outline</v-icon>
                        {{ $t(`messages['warnings count']`, [uploadState.warnings.length]) }}
                    </v-chip>
                </div>

                <div v-if="uploadState.visibleErrors">
                    <upload-errors :list="uploadState.errors"/>
                </div>

                <div v-if="uploadState.visibleWarnings">
                    <upload-errors icon-color="orange" icon="mdi-alert-outline" :list="uploadState.warnings"/>
                </div>

                <div class="title text-center my-6" v-show="uploadState.done && uploadState.uploaded.length === 0">
                    {{ $t(`messages['nothing uploaded']`) }}<br/><br/>
                    <v-btn outlined ripple color="blue-grey darken-1" @click="onClickRetry()">
                        {{ $t(`actions['new upload']`) }}
                    </v-btn>
                </div>


                <drop-zone v-show="!uploadState.done"
                           @drop="onSelectFiles"
                           :disabled="uploadState.selected">

                    <div class="page-upload__dz">
                        <div class="page-upload__drop">
                            {{ $t(`actions['drag to upload']`) }}
                        </div>
                        <div v-if="!uploadState.selected" class="page-upload__text">
                            {{ $t(`actions['drag or']`) }}
                            <span class="page-upload__manual">
                                <file-select @change="onSelectFiles"/>
                                {{ $t(`actions['choose file']`) }}
                            </span>
                        </div>
                        <div v-if="uploadState.selected" class="text-center page-upload__text">
                            <v-progress-circular
                                :size="50"
                                color="primary"
                                indeterminate
                            ></v-progress-circular>
                            <div>
                                {{ $t(`messages['loading in progress']`) }}
                                <animate-number v-model="uploadState.progress"/>
                                %
                            </div>
                        </div>
                    </div>
                </drop-zone>

                <!-- PHOTOGROUPS -->
                <div v-show="uploadState.loadingPhotogroups" class="text-center mt-8 mb-8">
                    <p class="headline">{{ $t(`messages['photoset building']`) }}</p>
                    <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </div>

                <div v-show="uploadState.loadedPhotogroups">
                    <grid-photogroups :draggable="true" v-model="uploadState.photogroups">
                        <template slot="preview-panel" slot-scope="props">
                            <lightbox-panel v-if="props.photo" :photo="props.photo.self"/>
                        </template>
                    </grid-photogroups>
                </div>
            </v-col>
        </v-row>


        <v-snackbar
            :timeout="6000"
            color="error"
            vertical
            v-model="uploadState.networkWarning">
            {{ $t(`errors['no internet']`) }}
        </v-snackbar>

    </v-container>
</template>

<script>
import { mapActions } from "vuex"
import * as Format from "@/util/format"
import DropZone from "storemoodify/lib/DropZone"
import FileSelect from "storemoodify/lib/FileSelect"
import AnimateNumber from "storemoodify/lib/AnimateNumber"
import GridPhotogroups from "../components/photogroups/GridPhotogroups"
import UploadTargetControl from "../components/UploadTargetControl"
import UploadErrors from "../components/UploadErrors"
import LightboxPanel from "../components/photogroups/LightboxPanel"
import Adapters from "../util/adapters"
import Uploader from "../services/Uploader"

const uploadState = () => ({
    uploaded: [],
    errors: [],
    warnings: [],
    photogroups: [],
    qrs: [],
    loadingPhotogroups: false,
    loadedPhotogroups: false,
    files: 0,
    progress: 0,
    networkWarning: false,
    selected: false,
    visibleErrors: false,
    visibleWarnings: false,
    done: false,
})

// Wait for worker
const sleep = () => new Promise(resolve => setTimeout(resolve, 8000))

const getAttachedData = (photogroups, qrs) => {
    const findPhotogroup = (time, offset = 0) => photogroups.find(p => p.startTime <= time + offset && p.endTime >= time - offset)
    return qrs.reduce((res, qr) => {
        const photogroup = findPhotogroup(qr.time) || findPhotogroup(qr.time, 80)

        if (!photogroup || photogroup.qrs.includes(qr.code)) {
            return res
        }

        if (!res[photogroup.id]) {
            res[photogroup.id] = {
                startTime: parseInt(photogroup.startTime),
                endTime: parseInt(photogroup.endTime),
                qrs: [],
            }
        }

        if (qr.time < res[photogroup.id].startTime) {
            res[photogroup.id].startTime = qr.time
        }

        if (qr.time > res[photogroup.id].endTime) {
            res[photogroup.id].endTime = qr.time
        }


        res[photogroup.id].qrs.push(qr.code)

        return res
    }, {})
}

export default {
    components: {
        DropZone,
        FileSelect,
        UploadTargetControl,
        AnimateNumber,
        GridPhotogroups,
        UploadErrors,
        LightboxPanel,
    },

    data() {
        return {
            target: null,
            uploadState: uploadState(),
        }
    },

    head() {
        return {
            title: this.$t("titles.upload"),
            toolbar: this.$t("toolbar.upload"),
        }
    },

    fetch({ store }) {
        return store.dispatch("user/getMyPlaces")
    },

    computed: {
        canUploadFiles() {
            return this.target !== null
        },

        getTargetPlaceName() {
            try {
                return Format.getName(this.target.place.Name)
            } catch (e) {
                return ""
            }
        },

        getTargetPhotographerName() {
            try {
                return Format.getUserName(this.target.photographer.Name)
            } catch (e) {
                return ""
            }
        },
    },

    methods: {
        ...mapActions({
            findPhotogroupsByPhotos: "photogroup/findByPhotos",
            setPhotogroupTime: "photogroup/setTime",
            attachPhotogroupQrs: "photogroup/attachQrs",
            deletePhotogroup: "photogroup/remove",
        }),

        onSubmitTarget(value) {
            this.target = value
        },

        clearTarget() {
            this.target = null
        },

        onClickClear() {
            this.clearTarget()
        },

        onSelectFiles(files) {
            console.log('onSelectFiles.files-----------------------', files)
            this.uploadState = uploadState()
            this.uploadState.selected = true
            this.uploadState.files = files.length

            const params = { PlaceID: this.target.place.ID, PhotographerID: this.target.photographer.ID }

            const uploader = new Uploader(this.$store, params)
                .on("done", () => this.onDoneUpload())
                .on("retry", () => this.uploadState.networkWarning = true)
                .on("detect:qr", (qr) => this.uploadState.qrs.push(qr))
                .on("progress", ({ percents }) => this.uploadState.progress = percents)
                .on("upload", (response) => this.uploadState.uploaded.push(response))
                .on("warning", warn => this.uploadState.warnings.push(warn))
                .on("error", error => this.uploadState.errors.push(error))

            uploader.add(files)
        },

        onDoneUpload() {
            this.uploadState.done = true
            // if (this.uploadState.uploaded.length) {
            //     this.fetchPhotogroups()
            // } else {
            //     this.uploadState.visibleErrors = true
            //     this.uploadState.visibleWarnings = false
            // }
        },

        onClickErrorsLabel() {
            this.uploadState.visibleErrors = !this.uploadState.visibleErrors
            this.uploadState.visibleWarnings = false
        },

        onClickWarningsLabel() {
            this.uploadState.visibleWarnings = !this.uploadState.visibleWarnings
            this.uploadState.visibleErrors = false
        },

        fetchPhotogroups() {
            this.uploadState.loadingPhotogroups = true
            const photoIds = this.uploadState.uploaded.map(p => p.ID)
            const self = this;

            // solution for internet connection
            (function fetch() {
                return sleep()
                    .then(() => self.findPhotogroupsByPhotos(photoIds))
                    .then(Adapters.photogroups)
                    .then(photogroups => self.applyPostUpload(photogroups).catch(() => null))
                    .then(() => self.findPhotogroupsByPhotos(photoIds))
                    .then(Adapters.photogroups)
                    .then(photogroups => self.onDonePrepare(photogroups))
                    .catch(e => {
                        // eslint-disable-next-line no-console
                        console.log("upload e", e)
                        fetch()
                    })
            })()
        },

        applyPostUpload(photogroups) {
            const qrs = this.uploadState.qrs
            const data = getAttachedData(photogroups, qrs)

            const awaits = Object.entries(data)
                .map(([id, attrs]) => Promise.all([
                    this.setPhotogroupTime({ id, start: attrs.startTime, end: attrs.endTime }),
                    this.attachPhotogroupQrs({ id, codes: attrs.qrs }),
                ]))

            return Promise.all(awaits)
        },

        onDonePrepare(photogroups) {
            if (photogroups.length === 0) {
                return this.fetchPhotogroups()
            }
            this.uploadState.loadingPhotogroups = false
            this.uploadState.loadedPhotogroups = true
            this.uploadState.done = true
            this.uploadState.photogroups = photogroups
        },

        onClickRetry() {
            this.uploadState = uploadState()
        },

        onClickSave() {
            this.$dialog.loading({ text: this.$t(`messages['saving']`) }).then((dialog) => {
                this.savePhotogroups()
                    .finally(() => this.redirectToPhotos(dialog))
            })
        },

        redirectToPhotos(dialog) {
            this.$router.push({ path: `/places/${this.target.place.ID}#photos` })
                .finally(() => dialog.close())
        },

        savePhotogroups() {
            const awaits = this.uploadState.photogroups.map(photogroup => {
                if (!photogroup.photos.length) {
                    return this.removePhotogroup(photogroup)
                }
                return this.movePhotogroupTime(photogroup)
            })

            return Promise.all(awaits)
        },

        removePhotogroup({ id }) {
            return this.deletePhotogroup(id)
        },

        movePhotogroupTime(photogroup) {
            const exifTimes = photogroup.photos.map(p => p.self.Metadata.EXIF.Time).sort((a, b) => a - b)
            const startTime = exifTimes[0]
            const endTime = exifTimes.pop()

            if (photogroup.startTime === startTime &&
                photogroup.endTime === endTime &&
                photogroup.self.Photos.length === photogroup.photos.length) {
                return
            }

            return this.setPhotogroupTime({
                id: photogroup.id,
                start: startTime,
                end: endTime,
            })
        },
    },
}
</script>

<style>
.page-upload__dz {
    display: flex;
    width: 100%;
    height: 300px;
    background: #f0f0f0;
    align-items: center;
    justify-content: center;
}

.page-upload__dz .page-upload__drop {
    display: none;
}

.drop-zone_active .page-upload__dz .page-upload__text {
    display: none;
}

.drop-zone_active .page-upload__dz .page-upload__drop {
    display: block;
}

.page-upload__manual {
    position: relative;
    color: #1a76d2;
}

.page-upload__manual input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
</style>
